import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import ch6 from '../asset1/images/contact-header-bg.jpg';
// import ch7 from '../asset1/images/stores/img-1.jpg';
// import ch8 from '../asset1/images/stores/img-2.jpg';
import ch34 from '../assets/images/bakc.jpg';

import ch1 from '../assets/images/Pak-Dream-1.jpg';
import ch2 from '../assets/images/Pak-Dream-2.jpg';






const Contact = (props)=>  {
    const [name, setname] = useState("") 
    const [lname, setlname] = useState("") 
    const [email, setemail] = useState("") 
    const [subject, setsubject] = useState("") 
    const [Message, setMessage] = useState("") 
    useEffect(() =>{
        window.scrollTo(0, 0)

    },[])

    const Submitdata = (e)=>{
        e.preventDefault()

        fetch("https://d-luxe-housebackend.vercel.app/touchIn",{
            method: 'POST' , 
            headers :  {
              "Content-Type" : "application/json" , 
            } , 
            body : JSON.stringify({
              name  ,
              lname  ,
              email  ,
              subject  ,
              Message  ,
            })
          })
          .then((res)=>res.json())
          .then((res2)  =>{
              console.log(res2)
              if (res2){
                swal("SucessFully Send "  )
                setname ("") 
                setlname("") 
                    setemail("") 
                    setsubject("") 
                    setMessage("") 
                // localStorage.setItem("User" , JSON.stringify("Yes") )
                    // props.history.push("/login")
              }
              else if (res2.Error){
                swal(res2.Error )
              }
              else{
                swal("Plz Try Again !"  )
              }
            // console.log(res2)
          })
          .catch((err)=>console.log(err))
        
    }



        return (
            <> 

            <div>
                <main className="main">
        <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="index-32.html">Home</a></li>
              {/* <li className="breadcrumb-item"><a href="#">Pages</a></li> */}
              <li className="breadcrumb-item active" aria-current="page">Tourism</li>
            </ol>
          </div>{/* End .container */}
        </nav>{/* End .breadcrumb-nav */}
        <div className="container">
          <div className="page-header page-header-big text-center" style={{backgroundImage:  'url('+ch34+')'}}>
            <h1 className="page-title">Tourism<span className="">keep in touch with us</span></h1>
          </div>{/* End .page-header */}
        </div>{/* End .container */}

       




<div className='container'>
             <h2 class="title mb-4 text-center">We Provide Best Services For You
</h2>
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center" style={{padding  :"30px 0px" ,color : "black", border:  "1px solid black"}}>
                                <span class="icon-box-icon">
                                    <i class="icon-info-circle"></i>
                                </span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title">Tours and Trips
</h3>
                                    <p>We offer our services to the Tourists and Group Tours of Offices or Schools.

</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center" style={{padding  :"30px 0px" ,color : "black", border:  "1px solid red", background: "#f23e3e"}}>
                                <span class="icon-box-icon">
                                    <i class="icon-star-o" style={{color :  "white"}}></i>
                                </span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title" style={{color :  "white"}}>Weddings
</h3>
                                    <p style={{color :  "white"}}>We provide our car rental service on weddings for Groom and other attendees.
</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center" style={{padding  :"30px 0px" ,color : "black", border:  "1px solid black"}}>
                                <span class="icon-box-icon">
                                    <i class="icon-heart-o"></i>
                                </span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title">Families / Individuals
</h3>
                                    <p>We offer vehicles like cars and vans to the individuals as well as to the families.

</p>
                                </div>
                            </div>
                        </div>
                    </div>








                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                           <img src={ch1} alt="" />
                        </div>

                        <div class="col-lg-6 col-sm-6">
                           <img src={ch2} alt="" />
                            
                        </div>

                        
                    </div>

<br />
<br />
             <h2 class="title mb-4 text-center">EXPLORE YOUR DESIRED ADVENTURES WITH PAK DREAM TOUR AND TOURISM

</h2>
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center" style={{padding  :"30px 10px" ,color : "black", border:  "1px solid black"}}>
                                <span class="icon-box-icon">
                                    {/* <i class="icon-info-circle"></i> */}
                                </span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title"> Naran Kaghan ​

</h3>
                                    <p>Naran Kaghan is a beautiful valley located in the Northern Areas of Pakistan. It is a very popular tourist destination and attracts a lot of visitors from all over the world. It is filled with lush green meadows, snow-capped mountains, crystal clear lakes and streams, and breathtaking views of the surrounding landscape. A trip to Naran Kaghan is sure to be an unforgettable experience, as it offers a great variety of activities to do.

</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center" style={{padding  :"30px 10px" ,color : "black", border:  "1px solid black"}}>
                                <span class="icon-box-icon">
                                    {/* <i class="icon-star-o"></i> */}
                                </span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title">Gilgit Baltistan
</h3>
                                    <p>Gilgit Baltistan is an amazing destination for a trip. With its mountains, glaciers, and rivers, it provides a unique and unforgettable experience. You can find a variety of activities, such as trekking, rock climbing, camping, and fishing. The scenery of the valleys and peaks is simply breathtaking. You can also explore the culture and tradition of the local people, which is very much alive and vibrant. The hospitality of the locals is also very warm.
</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center" style={{padding  :"30px 10px" ,color : "black", border:  "1px solid black"}}>
                                <span class="icon-box-icon">
                                    {/* <i class="icon-heart-o"></i> */}
                                </span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title">Hunza Valley
</h3>
                                    <p>Hunza Valley is a beautiful place to visit in Pakistan. It is located in the north of the country, in the Gilgit-Baltistan region. The valley is famous for its scenic beauty, with snow-capped mountains, lush green meadows and a picturesque river. The Hunza Valley is home to a number of attractions, including ancient forts, ancient monuments, and ancient architecture.

</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-6 col-lg-6 col-xl-3" style={{margin : "auto"}}>
		                	<div class="btn-wrap">
		                		{/* <span>Round Style</span> */}
		                		<a href="tel:+923332221927" class="btn btn-round" style={{background : "#f23e3e"}}><i class="icon-long-arrow-right"></i><span>Contact Us</span></a>
		                	</div>
		                </div>



</div>

       







      </main>{/* End .main */}





              
            </div>
            </>
           
        )
    
}


export default Contact;